import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { API_URL } from '../utils/request'

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  //   .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    lng: 'uz',
    backend: {
      /* translation file path */
      loadPath: API_URL + '/vocabulary?lang={{lng}}'
    },
    fallbackLng: 'uz',
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    }
  })

export default i18n
