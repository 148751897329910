import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import CategoryItem from '../category-item'
import CustomAutocomplete from '../../../../components/custom-autocomplite'
import Form from '../../../../components/form/Form'

import { useMutation, useQuery } from 'react-query'
import { ICategory } from '../../data/data'
import { useSearchParams } from 'react-router-dom'
import CustomCheckbox from '../../../../components/checkbox'
import { GET_VIDEO_CATEGORIES, GET_VIDEO_CATEGORY } from '../../queries'
import {
  BULK_DELETE_CHILD_VIDEOS,
  BULK_DELETE_CHILD_VIDEO_CATEGORY,
  CREATE_VIDEO_CATEGORY,
  UPDATE_VIDEO_CATEGORY
} from '../../mutatuions'
import { useTranslation } from 'react-i18next'

const initialValues = {
  name: {
    uz: '',
    ru: '',
    en: ''
  },
  paid: true
}

function CategoryForm() {
  const { i18n } = useTranslation()
  const { data, isLoading } = useQuery(['GET_VIDEO_CATEGORIES'], GET_VIDEO_CATEGORIES)
  const [searchParams, setSearchParams] = useSearchParams()
  //   const { data: childs } = useQuery(['categories', searchParams?.get('id')], () =>
  //     GET_VIDEO_CATEGORIES({ category_id: searchParams?.get('id') })
  //   )
  const { data: category, refetch } = useQuery(
    ['category', searchParams?.get('id')],
    () => GET_VIDEO_CATEGORY(`${searchParams.get('id')}`),
    {
      enabled: !!searchParams?.get('id')
    }
  )
  const { mutate: deleteChildCategory } = useMutation(BULK_DELETE_CHILD_VIDEO_CATEGORY, {
    onSuccess: () => {
      refetch()
    }
  })
  const { mutate: deleteArticle } = useMutation(BULK_DELETE_CHILD_VIDEOS, {
    onSuccess: () => {
      refetch()
    }
  })
  return (
    <Form
      getQuery={GET_VIDEO_CATEGORY}
      updateMutation={UPDATE_VIDEO_CATEGORY}
      createMutation={CREATE_VIDEO_CATEGORY}
      name='Video-Category'
      pageName='Video-Categories'
      initialValues={initialValues}
    >
      {({ getInfo, handleFinish, createInfo, updateInfo, register, handleSubmit, control, setValue, getValues }) => {
        // console.log(getValues('category_ids'), 'value', getInfo?.data?.data?.parent_category)
        return (
          <Box>
            <Typography
              typography={'h3'}
              style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                marginTop: '1.88rem'
              }}
            >
              Edit-Category
              {/* <Translations text='Edit' /> - <Translations text='Translation' /> */}
            </Typography>
            <form>
              <Grid container sm={12} spacing={'1.88rem'} p={'1.88rem'}>
                <Grid item sm={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    required
                    id='form-props-required'
                    label={'O`zbek'}
                    {...register('name.uz')}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    required
                    id='form-props-required'
                    label={'English'}
                    {...register('name.en')}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    required
                    id='form-props-required'
                    label={'Russian'}
                    {...register('name.ru')}
                  />
                </Grid>
                <Grid item sm={12}>
                  <CustomAutocomplete
                    loading={isLoading}
                    name='category_ids'
                    data={data?.data?.filter((el: ICategory) => el.id !== Number(searchParams.get('id'))) || []}
                    getOption={(value: any) => {
                      return value?.name?.[i18n.language]
                    }}
                    //   onchange={setValue}
                    multiple={true}
                    setValue={setValue}
                    value={getValues('category_ids') || []}
                    defaultValue={getInfo?.data?.data?.category_ids || []}
                    control={control}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    defaultValue={getInfo?.data?.data?.category_sort}
                    required
                    id='form-props-required'
                    label={'Sort'}
                    {...(getValues('category_ids')?.length
                      ? { ...register('category_sort') }
                      : { ...register('sort') })}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Button
                    variant='contained'
                    color='success'
                    fullWidth
                    onClick={handleSubmit((data: any) =>
                      handleFinish({
                        ...data,
                        category_ids: data?.category_ids?.map((el: any) => el.id) || null,
                        ...(data?.category_ids?.length > 0
                          ? {
                              category_sort: String(data?.category_sort || data?.sort)
                                ?.split(',')
                                ?.map((item: string) => +item),
                              sort: []
                            }
                          : {
                              sort: String(data?.category_sort || data?.sort)
                                ?.split(',')
                                ?.map((item: string) => +item),
                              category_sort: []
                            }),

                        paid: Number(data?.paid)
                      })
                    )}
                  >
                    {/* <Translations text='Submit' /> */}
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
            {category?.data?.child_category?.length > 0 && (
              <Box
                sx={{
                  p: '1.88rem'
                }}
              >
                <Typography typography={'h5'} sx={{ mb: '1.88rem' }}>
                  Categories
                </Typography>
                {category?.data?.child_category?.map((category: ICategory) => (
                  <CategoryItem
                    key={category?.id}
                    title={category?.name?.uz}
                    to='/categories'
                    onClick={() =>
                      deleteChildCategory({
                        id: searchParams.get('id'),
                        childId: category?.id
                      })
                    }
                  />
                ))}
              </Box>
            )}
            {category?.data?.videos?.length > 0 && (
              <Box
                sx={{
                  p: '1.88rem'
                }}
              >
                <Typography typography={'h5'} sx={{ mb: '1.88rem' }}>
                  Videos
                </Typography>
                {category?.data?.videos?.map((article: any) => (
                  <CategoryItem
                    key={article?.id}
                    title={article?.name?.uz}
                    to='/articles'
                    onClick={() =>
                      deleteArticle({
                        id: searchParams.get('id'),
                        childId: article?.id
                      })
                    }
                  />
                ))}
              </Box>
            )}
          </Box>
        )
      }}
    </Form>
  )
}

export default CategoryForm
