import * as React from 'react'
import { SVGProps } from 'react'
const TariffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      stroke='#323232'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M4.937 5.971A8.627 8.627 0 0 0 2.733 9.96M9.29 20.443a8.653 8.653 0 0 0 4.397-.188M2.734 14.041a8.627 8.627 0 0 0 2.204 3.988M19.405 14.464a8.64 8.64 0 0 1-2.212 3.678M17.193 5.858a8.65 8.65 0 0 1 2.212 3.678M9.29 3.557a8.653 8.653 0 0 1 4.397.188'
    />
    <path
      stroke='#323232'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M21.534 10.232A2.5 2.5 0 1 1 18 13.768a2.5 2.5 0 0 1 3.535-3.536M8.827 17.57a2.5 2.5 0 1 1-3.535 3.535 2.5 2.5 0 0 1 3.535-3.536M8.827 2.895a2.5 2.5 0 1 1-3.535 3.536 2.5 2.5 0 0 1 3.535-3.536'
    />
  </svg>
)
export default TariffIcon
