import { Box, Button, CircularProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import TextInput from '../../components/input'
import { useMutation } from 'react-query'
import { LOGIN } from './mutations'
import { useNavigate } from 'react-router-dom'

function Login() {
  const navigate = useNavigate()
  const { mutate, isLoading } = useMutation(LOGIN, {
    onSuccess: (data: any) => {
      localStorage.setItem('accessToken', data?.data?.access_token)
      navigate('/')
    }
  })
  const [data, setData] = useState({
    phone: '998913090318',
    password: '123456789'
  })
  return (
    <Box className={styles.container}>
      <img src={require('../../assets/images/logo.webp')} alt='logo' className={styles.image} />

      <p className={styles.title}>Profilga kirish</p>

      <TextInput
        className={styles.input}
        value={data.phone}
        onchange={el => setData({ ...data, phone: el })}
        label='Login'
      />
      <TextInput
        className={styles.input}
        value={data.password}
        onchange={el => setData({ ...data, password: el })}
        label='Password'
      />

      <Button variant='contained' onClick={() => mutate(data)}>
        {isLoading ? <CircularProgress /> : 'Submit'}
      </Button>
    </Box>
  )
}

export default Login
