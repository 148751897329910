import axios from 'axios'
import { toast } from 'react-hot-toast'
// import { toast } from 'react-hot-toast'
// import i18n from './i18n'

const production = false

export const API_URL = production ? process.env.REACT_APP_API_URL || '' : 'https://api.gippokamp.uz/api/v1'
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || 'https://api.gippokamp.uz'
export const SITE_URL = process.env.REACT_APP_SITE_URL || 'https://tezparcel.uz/'
export const WS_URL = production ? process.env.REACT_APP_WS_URL || '' : 'https://api.gippokamp.uz/api/v1/'

const client = axios.create({ baseURL: API_URL })
export const request = ({ ...options }) => {
  const auth = localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : ''
  client.defaults.headers.common.Authorization = auth
  client.defaults.headers.common.Accept = 'multipart/form-data'
  //   client.defaults.headers['Accept-language'] = i18n.language

  const onSuccess = (response: any) => {
    return response
  }
  const onError = (error: any) => {
    toast.error(`${error.message}, ${options.url}`)
    console.log(error.status, 'error')
    if (error.message === 'Request failed with status code 401') {
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }

  return client(options).then(onSuccess).catch(onError)
}
