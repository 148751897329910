export interface ILadingPhoto {
  id: number
  name: string
  photo: string
}

export const landingPhotos: ILadingPhoto[] = [
  {
    id: 1,
    name: 'photo',
    photo: ''
  }
]
