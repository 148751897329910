import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import SideNav from './side-nav'
import MobileSideNav from './mobile-side-nav'
import AppBarDesktop from './app-bar'
import AppBarMobile from './app-bar-mobile'
import Home from '../../pages/home'
import { Route, Routes, useNavigate, useRoutes } from 'react-router-dom'
import Users from '../../pages/users'
import Category from '../../pages/category'
import CategoryList from '../../pages/category-list'
import { Button } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

// const drawerWidth = '21.5625rem'

interface Props {
  children: React.ReactNode
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
}

export default function MainLayout(props: Props) {
  const [collapsed, setCollapsed] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  const navigate = useNavigate()
  const [broken, setBroken] = React.useState(false)

  return (
    <Box
      style={{
        flexGrow: 1,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '100vh',
        maxWidth: '100vw'
      }}
    >
      <CssBaseline />
      {window?.innerWidth > 600 ? (
        <SideNav
          collapsed={collapsed}
          broken={broken}
          toggled={toggled}
          setBroken={setBroken}
          setToggled={setToggled}
          setCollapsed={setCollapsed}
        />
      ) : (
        <MobileSideNav
          collapsed={collapsed}
          broken={broken}
          toggled={toggled}
          setBroken={setBroken}
          setToggled={setToggled}
          setCollapsed={setCollapsed}
        />
      )}

      <Box
        style={{
          flex: 1,
          backgroundColor: '#EEF2F5',
          margin: 0,
          height: '100vh',
          width: '100%',
          maxWidth: '100%',
          maxHeight: '100vh',
          position: 'relative',
          padding: '2rem',
          overflow: 'scroll'
        }}
      >
        {window.innerWidth > 600 ? <AppBarDesktop /> : <AppBarMobile toggled={toggled} setToggled={setToggled} />}
        <Box style={{ overflow: 'scroll', paddingTop: '7rem' }}>
          <Button
            sx={{
              mb: '0.5rem'
            }}
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeft />}
          >
            Orqaga
          </Button>
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}
