import React from 'react'
import PageLayout from '../../layouts/page'
import Table from '../../components/Table'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { categoryArr } from './data/data'
import Columns from './data/colums'
import CategoryForm from './components/form'
import { GET_STUDY_PLANS } from './queries'
import { BULK_DELETE_STUDY_PLAN } from './mutatuions'

function StudyPlan() {
  const navigate = useNavigate()
  const columns = Columns()
  return (
    <PageLayout
      buttons={[
        <Button onClick={() => navigate('/category-list')} variant='contained' key={'button'}>
          Category list
        </Button>
      ]}
      data={categoryArr}
      columns={columns}
      drawerComponent={<CategoryForm />}
      params={{ with_content: 1 }}
      pageName='Study-Plans'
      collectionQuery={GET_STUDY_PLANS}
      deleteBulkMutation={BULK_DELETE_STUDY_PLAN}
    />
  )
}

export default StudyPlan
