import React from 'react'
import MainLayout from '../../layouts/main'
import Content from './components/content'

function Messages() {
  return (
    <>
      <Content />
    </>
  )
}

export default Messages
